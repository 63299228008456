import { AppState } from './state';

export const getProgress = (state: AppState): AppState['progress'] => state.progress;
export const getShoppingList = (state: AppState): AppState['shoppingList'] => state.shoppingList;
export const getShift = (state: AppState): AppState['shift'] => state.shift;
export const getUserInfo = (state: AppState): AppState['userInfo'] => state.userInfo;
export const getChannels = (state: AppState): AppState['channels'] => state.channels;
export const getChannelScroll = (state: AppState): AppState['channelScroll'] => state.channelScroll;
export const getChannelSearch = (state: AppState): AppState['channelSearch'] => state.channelSearch;
export const getCurrentChannel = (state: AppState): AppState['currentChannel'] => state.currentChannel;
export const getForwards = (state: AppState): AppState['forwards'] => state.forwards;
