import { AppState, defaultState } from './state';
import { TypedAction } from './actions';
import { ShoppingListItem } from '../model/shopping-list';
import { Forward } from '../model/forward';

export const rootReducer = (state: AppState = defaultState, action: TypedAction): AppState => {
  switch (action.type) {
    case 'add-shopping-list-item': {
      const newItem = {
        ...action.item,
        focus: true,
      };
      return {
        ...state,
        shoppingList: sortShoppingList([...state.shoppingList.todo, ...state.shoppingList.done, newItem]),
      };
    }
    case 'remove-shopping-list-items':
      return {
        ...state,
        shoppingList: sortShoppingList(
          [...state.shoppingList.todo, ...state.shoppingList.done].filter((item) => action.ids.indexOf(item.id) === -1),
        ),
      };
    case 'update-shopping-list-item': {
      const newList = [...state.shoppingList.todo, ...state.shoppingList.done].map((item) =>
        item.id !== action.item.id
          ? item
          : {
              ...action.item,
              focus: undefined,
            },
      );
      return {
        ...state,
        shoppingList: sortShoppingList(newList),
      };
    }
    case 'set-shopping-list-items': {
      return {
        ...state,
        shoppingList: sortShoppingList(action.items),
      };
    }
    case 'set-keycloak-token': {
      return {
        ...state,
        keycloakToken: action.token,
      };
    }
    case 'set-progress':
      return { ...state, progress: action.progress };
    case 'set-user-info':
      return { ...state, userInfo: action.userInfo };
    case 'shift-set-desired':
      return { ...state, shift: { ...state.shift, desired: action.desired } };
    case 'shift-set-start':
      return { ...state, shift: { ...state.shift, startOfWork: action.start } };
    case 'shift-add-break':
      return { ...state, shift: { ...state.shift, breaks: [...state.shift.breaks, action.break] } };
    case 'shift-set-break':
      return {
        ...state,
        shift: {
          ...state.shift,
          breaks: [...state.shift.breaks].map((b, i) =>
            i === action.index ? { ...b, [action.breakType]: action.time } : b,
          ),
        },
      };
    case 'set-channels':
      return { ...state, channels: action.channels };
    case 'set-channels-scroll':
      return { ...state, channelScroll: action.scrollTop };
    case 'set-channel-search':
      return { ...state, channelSearch: action.searchTerm };
    case 'set-current-channel':
      return { ...state, channels: { c: state.channels?.c ?? [], n: action.channel }, currentChannel: action.channel };
    case 'set-forwards':
      return { ...state, forwards: action.forwards };
    case 'set-forward':
      const forwards = [...state.forwards].map((forward: Forward) => {
        if (forward.token === action.forward.token) {
          return { ...action.forward };
        }
        return forward;
      });
      if (forwards.findIndex((forward) => forward.token === action.forward.token) === -1) {
        forwards.push({ ...action.forward });
      }
      return { ...state, forwards };
    case 'remove-forward':
      return { ...state, forwards: state.forwards.filter((f) => f.token !== action.token) };
    default:
      return state;
  }
};

function sortShoppingList(items: ShoppingListItem[]) {
  return {
    todo: items.filter((i) => !i.done).sort((i1, i2) => i1.createdAt - i2.createdAt),
    done: items.filter((i) => i.done).sort((i1, i2) => i1.createdAt - i2.createdAt),
  };
}

export default rootReducer;
