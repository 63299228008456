import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak: Keycloak = new Keycloak({
  url: process.env.NODE_ENV === 'production' ? 'https://auth.l94.de/' : 'http://localhost:8080/',
  realm: 'l94',
  clientId: 'l94-frontend',
});

export default keycloak;
