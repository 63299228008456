import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducer';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AppState } from './state';
import { Action } from './actions';

const AppStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});
export default AppStore;

export type AppDispatch = ThunkDispatch<AppState, undefined, Action>;
export type AppThunkAction<R> = ThunkAction<R, AppState, undefined, Action>;
export type AsyncAppAction<R> = AppThunkAction<Promise<R>>;
