import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import Home from './component/Home/Home';
import Forwarder from './component/Forwarder/Forwarder';
import { useDispatch, useSelector } from 'react-redux';
import { SetKeycloakToken, setProgress, setUserInfo } from './store/actions';
import LoadingBar from 'react-top-loading-bar';
import { getProgress } from './store/selectors';
import PrivateRoute from './component/common/PrivateRoute/PrivateRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useKeycloak } from '@react-keycloak/web';
import { parseUser } from './utils/auth';
import { isRoute, RouteConf, RouteConfig } from './routes';
import Loading from './component/common/Loading/Loading';
import PageVisibility from 'react-page-visibility';
import { Observable, Subscriber } from 'rxjs';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { getVersion } from './api/version-api';
import { ListPayload } from './model/shopping-list';

export const primary: string = '#818CF8';

function InnerApp() {
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState<boolean>(false);
  const [authedBefore, setAuthedBefore] = useState<boolean>(false);
  const [shoppingListSubscriber, setShoppingListSubscriber] = useState<Subscriber<ListPayload>>();
  const [shoppingListObservable] = useState<Observable<ListPayload>>(
    new Observable<ListPayload>((s) => {
      setShoppingListSubscriber(s);
    }),
  );
  const { keycloak, initialized: keycloakInitialized } = useKeycloak();
  const { pathname } = useLocation();

  useEffect(() => {
    const interval = setTimeout(function () {
      if (keycloakInitialized) {
        clearInterval(interval);

        if (!!keycloak.authenticated) {
          dispatch(SetKeycloakToken(keycloak.token));
          setAuthedBefore(true);
          keycloak
            .loadUserInfo()
            .then((rawUserInfo) => {
              const userInfo = parseUser(rawUserInfo);
              dispatch(setUserInfo(userInfo));
              localStorage.setItem('authed', 'true');
              (Object.values(RouteConfig) as RouteConf[])
                .filter((c) => c.role === undefined || userInfo.roles.findIndex((r) => r === c.role) !== -1)
                .filter((c) => c.title !== RouteConfig['/konto'].title)
                .map((c) => c.component)
                .forEach((c) => c.preload());
            })
            .finally(() => setInitialized(true));
        } else {
          if (localStorage.getItem('authed') === 'true' && (pathname.replaceAll('/', '') === '' || isRoute(pathname))) {
            setAuthedBefore(true);
            keycloak.login();
          } else {
            setInitialized(true);
          }
        }
      }
    }, 50);
  }, [dispatch, keycloak, keycloakInitialized, pathname]);

  const {
    isLoading: versionLoading,
    isError: versionError,
    data: fetchedVersion,
  } = useQuery('getFEVersion', getVersion);

  const version = require('../package.json').version;

  useEffect(() => {
    if (versionLoading || versionError || fetchedVersion === undefined) {
      return;
    }
    if (version !== fetchedVersion) {
      navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => Promise.all(registrations.map((r) => r.unregister())))
        .then(() => window.location.reload());
    }
  }, [fetchedVersion, version, versionError, versionLoading]);

  const handlePageVisibility = useCallback(() => {
    if (authedBefore) {
      keycloak.updateToken(30).finally();
    }
  }, [authedBefore, keycloak]);

  if (!initialized) {
    if (authedBefore) {
      return <Loading />;
    }
    return <></>;
  }

  let loadingComponent = <></>;
  if (authedBefore) {
    loadingComponent = <Loading />;
  }

  return (
    <PageVisibility onChange={handlePageVisibility}>
      <div className="grid justify-stretch lg:m-auto p-4 h-full lg:max-w-screen-lg">
        <div className="h-auto p-4 lg:p-8 bg-dark-900 rounded-lg overflow-y-auto border-x-stone-700">
          <Suspense fallback={loadingComponent}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/konto" component={RouteConfig['/konto'].component} />
              <PrivateRoute path="/forwarder" role="l94:linker" component={RouteConfig['/forwarder'].component} />
              <PrivateRoute path="/keys" role="l94:keys" component={RouteConfig['/keys'].component} />
              <PrivateRoute
                path="/cart"
                role="l94:shopping-list"
                component={RouteConfig['/cart'].component}
                subscriber={shoppingListSubscriber}
                observable={shoppingListObservable}
              />
              <PrivateRoute path="/shift" role="l94:shifts" component={RouteConfig['/shift'].component} />
              <PrivateRoute
                path="/channels/:id"
                role="l94:channels"
                component={RouteConfig['/channels/:id'].component}
              />
              <PrivateRoute path="/channels" role="l94:channels" component={RouteConfig['/channels'].component} />
              <PrivateRoute path="/authed" role="l94:shopping-list" component={RouteConfig['/authed'].component} />
              <PrivateRoute path="/version" role="l94:shopping-list" component={RouteConfig['/version'].component} />
              <Route path="/:forwardToken" component={Forwarder} />
            </Switch>
          </Suspense>
        </div>
      </div>
    </PageVisibility>
  );
}

function App() {
  const dispatch = useDispatch();
  const progress = useSelector(getProgress);
  const queryClient = new QueryClient();

  const resetLoadingBar = useCallback(() => {
    dispatch(setProgress(0));
  }, [dispatch]);

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <LoadingBar color={primary} progress={progress} onLoaderFinished={resetLoadingBar} />
        <InnerApp />
        <ToastContainer
          {...ToastContainer.defaultProps}
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </Router>
    </QueryClientProvider>
  );
}

export default App;
