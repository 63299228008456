import { toast } from 'react-toastify';
import { ThunkDispatch } from 'redux-thunk';
import Toastbody from '../component/common/ToastBody/Toastbody';
import { Action, setProgress } from '../store/actions';
import { AppState } from '../store/state';

export const BACKEND_BASE_URL = process.env.NODE_ENV === 'production' ? 'https://api.l94.de' : 'http://localhost:36454';

type ProgressEvent = 'start' | 'success' | 'error';

export const httpProgress = (event: ProgressEvent, dispatch: ThunkDispatch<AppState, undefined, Action>): void => {
  switch (event) {
    case 'start':
      dispatch(setProgress(50));
      break;
    case 'success':
      dispatch(setProgress(100));
      break;
    case 'error':
      dispatch(setProgress(0));
      break;
  }
};

export const httpError = (reason: unknown, title: string = 'Fehler aufgetreten'): void => {
  toast.error(Toastbody({ title, body: `${reason}` }));
};
