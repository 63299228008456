import { ListPayload, ShoppingList } from '../model/shopping-list';
import { Break, ShiftTime } from '../model/shift';
import { UserInfo } from '../utils/auth';
import { ChannelInfo, SearchResult } from '../api/channels-api';
import { Forward } from '../model/forward';
import { Subscriber } from 'rxjs';

export type AppState = {
  userInfo?: UserInfo;
  isMobile: boolean;
  progress: number;
  shoppingList: ShoppingList;
  shift: { desired: ShiftTime; startOfWork: ShiftTime; breaks: Break[] };
  resumeToken: string | undefined;
  keycloakToken: string | undefined;
  channels: SearchResult | undefined;
  channelScroll: number;
  channelSearch: string;
  currentChannel?: ChannelInfo;
  forwards: Forward[];
  listPayloadSubscriber: Subscriber<ListPayload> | undefined;
};

export const defaultState: AppState = {
  userInfo: undefined,
  isMobile: false,
  progress: 0,
  shoppingList: { todo: [], done: [] },
  shift: { desired: { hours: 7, minutes: 48 }, startOfWork: { hours: 7, minutes: 0 }, breaks: [] },
  resumeToken: undefined,
  keycloakToken: undefined,
  channels: undefined,
  channelScroll: 0,
  channelSearch: '',
  currentChannel: undefined,
  forwards: [],
  listPayloadSubscriber: undefined,
};
