import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Role } from '../../../utils/auth';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../store/selectors';
import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Observable, Subscriber } from 'rxjs';
import { ListPayload } from '../../../model/shopping-list';

type PrivateRouteProps = RouteProps & {
  component: React.FunctionComponent<any>;
  path: string | string[];
  role: Role;
  subscriber?: Subscriber<ListPayload>;
  observable?: Observable<ListPayload>;
};

export default function PrivateRoute({ component, path, role, ...rest }: PrivateRouteProps) {
  const { keycloak } = useKeycloak();
  const userInfo = useSelector(getUserInfo);
  const hasAccess = keycloak?.authenticated && !!userInfo && userInfo.roles.indexOf(role) !== -1;

  return (
    <Route
      {...rest}
      render={(props) => (hasAccess ? React.createElement(component, { ...props, ...rest }) : <Redirect to="/" />)}
    />
  );
}
