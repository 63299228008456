import axios, { AxiosResponse } from 'axios';
import { BACKEND_BASE_URL } from './common';

type VersionInfo = {
  version: string;
};

export const getVersion = (): Promise<string> =>
  axios.get(BACKEND_BASE_URL + '/frontend/version').then((res: AxiosResponse<VersionInfo, any>) => res.data.version);

export const setVersion = (version: string): Promise<string> =>
  axios
    .post(BACKEND_BASE_URL + '/frontend/version/set', { version } as VersionInfo)
    .then((res: AxiosResponse<VersionInfo, any>) => res.data.version);
