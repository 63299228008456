import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getSuffix } from '../../utils/utils';
import Loading from '../common/Loading/Loading';
import { forwardByToken } from '../../api/forward-api';
import { useDispatch } from 'react-redux';

type ForwarderProps = {
  location: Location;
};

function Forwarder({ location }: ForwarderProps) {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const token = getSuffix('/', location.pathname);
    if (!!token) {
      dispatch<any>(forwardByToken(token, () => history.push('/')));
    }
  }, [dispatch, history, location.pathname]);
  return <Loading />;
}

export default Forwarder;
