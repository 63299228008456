import axios, { AxiosResponse } from 'axios';
import { AppThunkAction } from '../store/store';
import { BACKEND_BASE_URL, httpError, httpProgress } from './common';
import { Forward, Token, User } from '../model/forward';
import { removeForward, setForward, setForwards } from '../store/actions';

export const forwardByToken = (token: string, noForwardCallback: () => void): AppThunkAction<Promise<void>> => {
  return async (dispatch, getState) => {
    try {
      httpProgress('start', dispatch);
      await axios
        .post(BACKEND_BASE_URL + '/forward/token', { token })
        .then((response: AxiosResponse<Forward, any>) => {
          httpProgress('success', dispatch);
          if (response.data.forward && !!response.data.url) {
            console.log(`https://l94.de – Weiterleitung nach: ${response.data.url}`);
            document.title = 'Du wirst weitergeleitet…';
            window.location.href = response.data.url;
          } else {
            noForwardCallback();
          }
        })
        .catch((reason: unknown) => {
          httpProgress('error', dispatch);
          httpError(reason, 'Abfrage fehlgeschlagen');
        })
        .finally();
    } catch (error) {
      httpProgress('error', dispatch);
      console.error(error);
      httpError(error);
    }
  };
};

export const getList = (): AppThunkAction<Promise<void>> => {
  return async (dispatch, getState) => {
    try {
      httpProgress('start', dispatch);
      await axios
        .get(BACKEND_BASE_URL + '/forward/forward-list')
        .then((response: AxiosResponse<User, any>) => {
          httpProgress('success', dispatch);
          dispatch(setForwards(response.data.forwards));
        })
        .catch((reason: unknown) => {
          httpProgress('error', dispatch);
          httpError(reason, 'Laden der Liste fehlgeschlagen');
        })
        .finally();
    } catch (error) {
      httpProgress('error', dispatch);
      console.error(error);
      httpError(error);
    }
  };
};

export const createForward = (url: string): AppThunkAction<Promise<void>> => {
  return async (dispatch, getState) => {
    try {
      httpProgress('start', dispatch);
      await axios
        .post(BACKEND_BASE_URL + '/forward/new', { url })
        .then((response: AxiosResponse<Token, any>) => {
          httpProgress('success', dispatch);
          dispatch(setForward({ forward: true, token: response.data.token, url }));
        })
        .catch((reason: unknown) => {
          httpProgress('error', dispatch);
          httpError(reason, 'Erstellen der Weiterleitung fehlgeschlagen');
        })
        .finally();
    } catch (error) {
      httpProgress('error', dispatch);
      console.error(error);
      httpError(error);
    }
  };
};

export const changeForward = (token: string, url: string): AppThunkAction<Promise<void>> => {
  return async (dispatch, getState) => {
    try {
      httpProgress('start', dispatch);
      await axios
        .post(BACKEND_BASE_URL + '/forward/change', { token, url })
        .then((response: AxiosResponse<Token, any>) => {
          httpProgress('success', dispatch);
          dispatch(
            setForward({ forward: getState().forwards.find((f) => f.token === token)?.forward ?? false, token, url }),
          );
        })
        .catch((reason: unknown) => {
          httpProgress('error', dispatch);
          httpError(reason, 'Bearbeiten der Weiterleitung fehlgeschlagen');
        })
        .finally();
    } catch (error) {
      httpProgress('error', dispatch);
      console.error(error);
      httpError(error);
    }
  };
};

export const deleteForward = (token: string): AppThunkAction<Promise<void>> => {
  return async (dispatch, getState) => {
    try {
      httpProgress('start', dispatch);
      await axios
        .post(BACKEND_BASE_URL + '/forward/delete', { token })
        .then((response: AxiosResponse<Token, any>) => {
          httpProgress('success', dispatch);
          dispatch(removeForward(token));
        })
        .catch((reason: unknown) => {
          httpProgress('error', dispatch);
          httpError(reason, 'Entfernen der Weiterleitung fehlgeschlagen');
        })
        .finally();
    } catch (error) {
      httpProgress('error', dispatch);
      console.error(error);
      httpError(error);
    }
  };
};
