import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import AppStore from './store/store';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { AuthClientEvent, AuthClientError, AuthClientTokens } from '@react-keycloak/core';
import keycloak from './keycloak';
import { SetKeycloakToken } from './store/actions';
import axios from 'axios';
import { httpError } from './api/common';

export type KeyCloakEventHandler = (eventType: AuthClientEvent, error?: AuthClientError) => void;
const keycloakEventHandler = (eventType: AuthClientEvent, error?: AuthClientError) => {
  switch (eventType) {
    case 'onTokenExpired':
    case 'onAuthRefreshError':
      keycloak.updateToken(300);
      break;
    case 'onReady':
    case 'onAuthSuccess':
    case 'onAuthLogout':
    case 'onAuthRefreshSuccess':
      break;
    default: {
      httpError(eventType);
    }
  }
};

const onKeycloakTokens = (tokens: AuthClientTokens) => {
  AppStore.dispatch(SetKeycloakToken(tokens.token));
  if (!!tokens.token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokens.token;
  }
};

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <ReactKeycloakProvider
    autoRefreshToken={true}
    authClient={keycloak}
    onEvent={keycloakEventHandler}
    onTokens={onKeycloakTokens}
  >
    <React.StrictMode>
      {/*https://github.com/react-keycloak/react-keycloak/issues/182#issuecomment-1128477421*/}
      <Provider store={AppStore}>
        <App />
      </Provider>
    </React.StrictMode>
  </ReactKeycloakProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
