import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function Home() {
  const { keycloak } = useKeycloak();
  const history = useHistory();

  useEffect(() => {
    if (!!keycloak.authenticated) {
      history.replace('/authed');
    }
  }, [history, keycloak.authenticated]);

  return <></>;
}
